import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Entry',
    component: () => import(/* webpackChunkName: "about" */ '../views/Entry.vue'),
  },
  {
    path: '/EntryAdmin',
    name: 'EntryAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/EntryAdmin.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Dashboard.vue'),
  },
  {
    path: '/comments',
    name: 'comments',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Comments.vue'),
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Mobile.vue'),
  },
  {
    path: '/cp1',
    name: 'CP1',
    component: () => import(/* webpackChunkName: "about" */ '../views/cp1_DefenseCounselA.vue'),
  },
  {
    path: '/cp2',
    name: 'CP2',
    component: () => import(/* webpackChunkName: "about" */ '../views/cp2_ProsecutorA.vue'),
  },
  {
    path: '/cp3',
    name: 'CP3',
    component: () => import(/* webpackChunkName: "about" */ '../views/cp3_DoctorA.vue'),
  },
  {
    path: '/cp4',
    name: 'CP4',
    component: () => import(/* webpackChunkName: "about" */ '../views/cp4_ProsecutorB.vue'),
  },
  {
    path: '/cp5',
    name: 'CP5',
    component: () => import(/* webpackChunkName: "about" */ '../views/cp5_DoctorB.vue'),
  },
  {
    path: '/cp6',
    name: 'CP6',
    component: () => import(/* webpackChunkName: "about" */ '../views/cp6_DefenseCounselB.vue'),
  },
  {
    path: '/end',
    name: 'End',
    component: () => import(/* webpackChunkName: "about" */ '../views/End.vue'),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
