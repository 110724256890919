import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VModal from 'vue-js-modal';
import VideoPlayer from 'vue-videojs7';
import './plugins/element.js';
// import 'vue-video-player/src/custom-theme.css';
import NoSleep from 'nosleep.js/dist/NoSleep.min.js';
Vue.prototype.$NoSleep = NoSleep;

Vue.config.productionTip = false;

Vue.use(VModal, { dynamicDefaults: { height: 'auto' }}).use(VideoPlayer);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
